<template>
  <v-app class="login-content">
    <login-view />

    <toast />

    <!-- <login-footer /> -->
  </v-app>
</template>

<style>

  #app.login-content {
    /* background-image: linear-gradient(248.66deg, rgba(255, 71, 102, 0.25) 0%, rgba(90, 129, 237, 0.25) 100%), linear-gradient(180deg, rgba(38, 39, 44, 0.75) 0%, rgba(38, 39, 44, 0.75) 100%), url(/img/home-hero-00-lg.jpg) !important; */
    background-position: 0 0, 0 0, 75% 50%;
    background-size: auto, auto, cover;
    background-attachment: scroll;
  }
  #app.login-content {
    background-image: white;
  }
  @media (min-width: 960px) {
    /* #app.login-content {
      background-image: linear-gradient(248.66deg, rgba(255, 71, 102, 0.25) 0%, rgba(90, 129, 237, 0.25) 100%), linear-gradient(180deg, rgba(38, 39, 44, 0.75) 0%, rgba(38, 39, 44, 0.75) 100%), url(/img/home-hero-00-lg.jpg) !important;
    } */
  }


</style>


<script>
  // Utilities
  import { sync } from 'vuex-pathify'

  export default {
    name: 'LayoutLogin',

    components: {
      LoginView: () => import('./View'),
      Toast: () => import('@/components/Toast'),
    },
    computed: {
      toast: sync('app/toast'),
    }
  }
</script>
